import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/user/LandingPage';
import './App.css';
import "./css/user/Button.css"
import "./css/user/Animations.css"
import "./css/user/UserAuth.css"
import "./css/user/LandingPage.css"
import "./css/user/Offer.css"
import "./css/user/BookingForm.css"
import "./css/user/Loading.css"
import "./css/user/ErrorPage.css"
import "./css/user/Faq.css"
import "./css/user/KeyWords.css"
import "./css/user/ClientSaysComponent.css"
import ErrorPage from './pages/other/ErrorPage';
import ConfirmBookingPage from './pages/user/ConfirmBookingPage';
import ChandigarhLandingPage from './pages/user/ChandigarhLandingPage';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />}></Route>
          <Route path='/chandigarh' element={<ChandigarhLandingPage />}></Route>

          <Route path='/booking-confirm' element={<ConfirmBookingPage />}></Route>
          <Route path='/*' element={<ErrorPage />}></Route>

        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
