import React from "react";
import { Link } from "react-router-dom";
import LandingNav from "../../components/user/LandingNav";

const ErrorPage = () => {
  return (
    <div>
        <LandingNav/>
      <div className="error-page">
        <h1>404 Error</h1>
        <h2>page not found</h2>
        <h3>
          Contact : <a href="tel:+919658153153">+91 9658 153 153</a>
        </h3>
        <h3>
          <Link to="/">go to home <i className="fa-solid fa-right-to-bracket"></i></Link>
        </h3>
      </div>
    </div>
  );
};

export default ErrorPage;
