import React from 'react'

const PriceComponent = () => {
  return (
    <div><div className="home-container-5">
    <div id="facial" className="home-container-5-heading">
      Our Price
    </div>


    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - FACIAL
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">VLCC</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,399</s> Rs. 1,199/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">NATURES</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,699</s> Rs. 1,399/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">LOTUS</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,999</s> Rs. 1,599/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            SHEHNAZ HERBAL
          </div>
          <div className="home-container-5-col-1-price"><s>Rs.2,499</s> Rs. 1,999/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            O3+ 
          </div>
          <div className="home-container-5-col-1-price"><s>Rs.3,599</s> Rs. 2,999/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            O3+ SKIN WHITENING
          </div>
          <div className="home-container-5-col-1-price"><s>Rs.3,999</s> Rs. 3,199/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RAAGA</div>
          <div className="home-container-5-col-1-price"><s>Rs.4,599</s> Rs. 3,999/-</div>
        </div>
        <div id="pedicure" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - PEDICURE
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">AROMA</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,299</s> Rs. 1,199/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">VLCC</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,599</s> Rs. 1,399/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">O3+</div>
          <div className="home-container-5-col-1-price"><s>Rs.2,199</s> Rs. 1,799/-</div>
        </div>
        <div id="manicure" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>


    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - MANICURE
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">AROMA</div>
          <div className="home-container-5-col-1-price"><s>Rs.899</s> Rs. 699/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">VLCC</div>
          <div className="home-container-5-col-1-price"><s>Rs.799</s> Rs. 999/-</div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">O3+</div>
          <div className="home-container-5-col-1-price"><s>Rs.1,399</s> Rs. 1,199/-</div>
        </div>
        <div id="upper-body-wax" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - UPPER BODY WAXING
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">NORMAL</div>
          <div className="home-container-5-col-1-price">
            <s>Rs.3,799</s> Rs. 2,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RICA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.4,999</s> Rs. 3,999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RAAGA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.5,999</s> Rs. 4,999/-
          </div>
        </div>
        <div id="full-body" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - FULL BODY WAXING <br />
        <span>(EXCLUDING UNDER CLOTH PART)</span>
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">NORMAL</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.4,599</s> Rs. 3,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RICA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.5,999</s> Rs. 4,799/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RAAGA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.7,999</s> Rs. 5,999/-
          </div>
        </div>
        <div id="under-cloth-and-back" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - UNDER CLOTH & BACK WAX
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">NORMAL</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.4,499</s> Rs. 3,999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RICA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.5,999</s> Rs. 4,999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            PEEL OFF WAX
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.6,999</s> Rs. 5,599/-
          </div>
        </div>
        <div id="under-cloth-hair-wax" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - UNDER CLOTH HAIR WAX
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">NORMAL</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.2,999</s> Rs. 2,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">RICA</div>
          <div className="home-container-5-col-1-price">
          <s>Rs.3,999</s> Rs. 3,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            PEEL OFF WAX
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.4,999</s> Rs. 4,599/-
          </div>
        </div>
        <div id="beauty" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S - BEAUTY SERVICES
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY SCRUB
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.2,999</s> Rs. 2,799/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY DTAN (O3+)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.3,599</s> Rs. 2,999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY HALDI & CHANDAN PACK
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.2,599</s> Rs. 1,999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY POLISH
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.5,599</s> Rs. 4,799/-
          </div>
        </div>
        <div id="shave" className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        BODY TRIM & SHAVE
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY HAIR TRIM
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.2,999</s> Rs. 2,499/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BODY HAIR SHAVE
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.3,599</s> Rs. 3,399/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER CLOTH HAIR TRIM
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,599</s> Rs. 1,199/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER CLOTH HAIR SHAVE
          </div>
          <div id="men-wax" className="home-container-5-col-1-price">
          <s>Rs.1,999</s> Rs. 1,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
      {/* ------------ price end ------------ */}

     

      {/* ------------ price end ------------ */}
      {/* ------------ price end ------------ */}

      {/* <div className="home-container-hr-line">
        <hr />
      </div> */}

      {/* ------------ price start ------------ */}
      {/* <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
             LINE SHAVE
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.2,599</s> Rs. 1,799/-
          </div>
        </div>
        <div id="men-wax" className="home-container-5-col-2">
        </div>
      </div> */}

      {/* ------------ price end ------------ */}
    </div>

    <div className="home-container-top"></div>

    <div className="home-container-5-section">
      <div className="home-container-5-section-heading">
        GLAZMA MEN'S WAXING
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            CHEST WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.999</s> Rs. 799/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            CHEST WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,299</s> Rs. 1,099/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            STOMACH WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.799</s> Rs. 599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            STOMACH WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,099</s> Rs. 899/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BACK WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,199</s> Rs. 999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL BACK WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,599</s> Rs. 1,399/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER ARMS WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.399</s> Rs. 199/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER ARMS WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.499</s> Rs. 299/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            SHOULDER WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.799</s> Rs. 599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            SHOULDER WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.999</s> Rs. 799/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL ARMS WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.899</s> Rs. 699/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL ARMS WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,199</s> Rs. 999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL LEGS WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,199</s> Rs. 999/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            FULL LEGS WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.1,699</s> Rs. 1,499/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}


      {/* ------------ price end ------------ */}

     

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER CLOTH HAIR WAX (NORMAL)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.3,099</s> Rs. 2,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}

      <div className="home-container-hr-line">
        <hr />
      </div>

      {/* ------------ price start ------------ */}
      <div className="home-container-5-cols">
        <div className="home-container-5-col-1">
          <div className="home-container-5-col-1-heading">
            UNDER CLOTH HAIR WAX (RICA)
          </div>
          <div className="home-container-5-col-1-price">
          <s>Rs.4,099</s> Rs. 3,599/-
          </div>
        </div>
        <div className="home-container-5-col-2">
          {/* <button>Add</button> */}
        </div>
      </div>

      {/* ------------ price end ------------ */}
    </div>
  </div></div>
  )
}

export default PriceComponent