import React from "react";

const MenuComponent = ({ open, handleMenu }) => {

  if (!open) return null;

  return (
    <div> 

        <div className="menu-list">
          
          <div className="home-fix-menu">
          <div className="home-fix-menu-button"><span onClick={handleMenu}><i class="fa-solid fa-circle-xmark"></i></span></div>
            <div className="home-fix-menu-content">
              <div className="home-fix-menu-content-packages">
                <div className="home-fix-menu-content-heading-1">Grooming Menu</div>
                <div className="home-fix-menu-content-heading">Packages</div>
                <div className="home-fix-menu-content-list">
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#basic-groom" onClick={handleMenu}>
                        Men's Basic Grooming
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#radiance-package" onClick={handleMenu}>
                        {" "}
                        Men's Radiance Package
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#relax-groom" onClick={handleMenu}>
                        Men's Relax & Groom Special
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#special-grooming" onClick={handleMenu}>
                        Men's Special Grooming
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#oxy-glow" onClick={handleMenu}>
                        Men's Oxy-Glow Package
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#full-body-grooming" onClick={handleMenu}>
                        Men's Full Body Grooming
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#body-waxing" onClick={handleMenu}>
                        Men's body Waxing
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#under-cloth" onClick={handleMenu}>
                        Men's Under Cloth Grooming
                      </a>
                    </div>
                  </div>
                  
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#honeymoon-special" onClick={handleMenu}>
                        Men's Honeymoon Special
                      </a>
                    </div>
                  </div>
                  

                  {/* <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#Regular-Groom" onClick={handleMenu}>
                      Men’s Regular Groom Package
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#Exclusive-Groom" onClick={handleMenu}>
                      Men’s Exclusive Groom Package
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#Premium-Groom" onClick={handleMenu}>
                      Men’s Premium Groom Package
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#Wedding-Day-Dapper" onClick={handleMenu}>
                      Men's Wedding Day Dapper Delight
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#Gleaming-Groom" onClick={handleMenu}>
                      Men's Gleaming Groom's Journey
                      </a>
                    </div>
                  </div> */}


                  
                </div>
              </div>
              <br />
              <div className="home-fix-menu-content-prices">
                <div className="home-fix-menu-content-heading">Prices</div>
                <div className="home-fix-menu-content-list">
                 
                 

                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#facial" onClick={handleMenu}>
                        glazma men's - facial
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#pedicure" onClick={handleMenu}>
                        glazma men's - pedicure
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#manicure" onClick={handleMenu}>
                        glazma men's - manicure
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#upper-body-wax" onClick={handleMenu}>
                        glazma men's - upper body waxing
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#full-body" onClick={handleMenu}>
                        glazma men's - full body waxing
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#under-cloth-and-back" onClick={handleMenu}>
                        glazma men's - under cloth & back wax
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#under-cloth-hair-wax" onClick={handleMenu}>
                        glazma men's - under-cloth hair wax
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#beauty" onClick={handleMenu}>
                        glazma men's - beauty services
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#shave" onClick={handleMenu}>
                        body trim & shave
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="home-fix-menu-content-list-icon">
                      &#10095;&#10095;
                    </div>{" "}
                    <div className="home-fix-menu-content-list-text">
                      <a href="#men-wax" onClick={handleMenu}>
                        glazma men's waxing
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
    </div>

    
    
    
    </div>
  )
}

export default MenuComponent