import React from 'react'
import { TypeAnimation } from "react-type-animation";
import header from "../../media/images/glazma-home-header-2.png";


const LandingHeader = ({ setBookingForm}) => {
  return (
    <div>
          <div className="home-header">
          <div className="home-header-content">
            <div className="home-header-heading-1">
              {/* men's doorstep beauty service */}
              {/* doorstep men's <br /> grooming */}
              men's grooming <br /> service at home
            </div>
            <div className="home-header-heading-2">
              <TypeAnimation
                sequence={[
                  "lighten the dark skin",
                  2500,
                  "pre wedding groom",
                  2500,
                  "facials",
                  2500,
                  "full body wax",
                  2500,
                  "full body scrub",
                  2500,
                  "full body polishing",
                  2500,
                  "manicure & pedicure",
                  2500,
                  "body therapy at home",
                  2500,
                  "hair spa at home",
                  2500,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </div>

            <div className="home-header-button">
              <div>
                <div onClick={()=>{setBookingForm(true)}}>Book an Appointment</div>
              </div>
            </div>
          </div>

          <div className="home-header-img">
            <img  title="Mens waxing price" src={header} alt=" Mens waxing near me" />
          </div>
        </div>
    </div> 
  )
}

export default LandingHeader