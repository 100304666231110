import React, { useState } from "react";
import LandingNav from "../../components/user/LandingNav";
import LandingHeader from "../../components/user/LandingHeader";
import LandingHeader2 from "../../components/user/LandingHeader2";
import LandingOffer from "../../components/user/LandingOffer";
import LandingCategory from "../../components/user/LandingCategory";
import PackageComponent from "../../components/user/PackageComponent";
import PriceComponent from "../../components/user/PriceComponent";
import TopButton from "../../components/user/buttons/TopButton";
import TestimonialComponent from "../../components/user/TestimonialComponent";
import LandingFooter from "../../components/user/LandingFooter";
import LandingEnd from "../../components/user/LandingEnd";
import ContactButtons from "../../components/user/buttons/ContactButtons";
import MenuComponent from "../../components/user/MenuComponent";
import BookingForm from "../../components/user/BookingForm";
import { Helmet } from 'react-helmet';
import ClientSaysComponent from "../../components/user/ClientSaysComponent";


const LandingPage = () => {

  const [menu, setMenu] = useState(false);

  const handleChange = () => {
    setMenu(!menu);
  };

  // ----------------

  const [popupBtn, setPopupBtn] = useState(true);
  
  
  // ----------------


  const [bookingForm, setBookingForm] = useState(false);  

  return (
    <div>


<Helmet>
        <title>Glazma : Luxury Men's Grooming Services in Noida, Delhi NCR</title>
        <meta name="description" content="
        Glazma offers Men's grooming services at your doorstep in Noida, Delhi NCR Now Call Men's grooming experts and Makeup artist at home.
        " />
      </Helmet>



      
      {
        bookingForm === false? ("")
        :<BookingForm  setBookingForm={setBookingForm}  />
      }
      
      <div>
      <div className="landing-page">
        <div className="landing-page-web-main">
          <div className="home-top">
          <span>get upto 50% off on all services</span> <div>*Limited Period Offer</div>

          </div>
          <div className="landing-page-nav">
            <LandingNav />
          </div>
          
          <div className="landing-page-content">
            <div className="landing-page-content-col-1 landing-small-display-none">
              <div className="landing-page-content-col-1-header">
                <LandingHeader2 />
              </div>
              <div className="landing-small-display-none">
                <br />
              </div>
              <div className="landing-page-web-content-category">
                
                <LandingCategory setPopupBtn={setPopupBtn} popupBtn={popupBtn}/>

                <div className="landing-page-web-content-menu">
                  <span onClick={handleChange}>
                    {menu ? (
                      <span>
                        <i className="fa-regular fa-circle-xmark"></i> &nbsp;
                        Close menu
                      </span>
                    ) : (
                      <span>
                        <i className="fa-solid fa-bars"></i> &nbsp; Glazma Men's
                        Grooming Menu
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="landing-page-content-col-2">
              <LandingHeader setBookingForm={setBookingForm} />

              <div className="landing-large-display-none">
                <LandingHeader2 />
                <LandingOffer />
                <LandingCategory setPopupBtn={setPopupBtn}/>
              <div className="home-container-top"></div>
              </div>

              <ClientSaysComponent/>
              <div  id='holiday' className="home-container-top"></div>

              <PackageComponent />
              <div  className="home-container-top"></div>


              <PriceComponent />
            </div>
          </div>
        </div>
        <div className="landing-page-menu-position">
          {menu && (
            <MenuComponent open={menu} handleMenu={() => setMenu(false)} />
          )}
        </div>

        <div className="home-container-top"></div>
        <TestimonialComponent />
        
        {/* <div className="home-container-top"></div> */}
        {/* <KeyWords/> */}
        <LandingFooter />

        <div className="landing-page-end-fix landing-large-display-none">
          <LandingEnd />
        </div>
      </div>

      <div className="landing-page-fix-buttons">

        <ContactButtons  />
        <TopButton />
      </div>
    </div>
    
      
      
    </div>
  );
};

export default LandingPage;
