import React from "react";

// import bodyWax from "../../media/images/full-body-wax.jpg";
// import shave from "../../media/images/body-shave1.jpg";
// import groom from "../../media/images/wedding-groom.jpg";
// import spa from "../../media/images/hair-color-spa.jpg";
// import spa from "../../media/images/Honeymoon Special Grooming.jpg";
// import massage from "../../media/images/massage.jpg";
// import massage from "../../media/images/Holiday Special Grooming.jpg";
// import pedicure from "../../media/images/pedicure.jpg";


// import bodyWax from "../../media/images/Full Body Waxing.jpg";
// import groom from "../../media/images/Groom Package.jpg";
// import wax from "../../media/images/under-cloth-wax.jpg";
// import shave from "../../media/images/Body Hair TrimShave.jpg";
// import spa from "../../media/images/honey-moon.jpg";
// import detan from "../../media/images/facial.jpg";
// import massage from "../../media/images/holiday.jpg";


import facial from "../../media/images/icons/facial icon.png"
import underCloth from "../../media/images/icons/under-cloth.png"
import fullBody from "../../media/images/icons/full-body.png"
import groomPackage from "../../media/images/icons/groom-package.png"
// import pedicure from "../../media/images/icons/pedicure.png"
import manicure from "../../media/images/icons/manicure.png"
import bodyTrim from "../../media/images/icons/body-trim.png"
import honeymoon from "../../media/images/icons/honeymoon.png"
import holiday from "../../media/images/icons/holiday.png"


import { Link } from "react-router-dom";


const LandingCategory = ({setPopupBtn}) => {
  return (
    <div>
      <div className="home-container-3">
        <div className="home-container-3-content">

        <a title="Cheap Waxing Near Me"  href="#facial" className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              {/* <img title="Mens beauty parlour in noida"  src={detan} alt="Mens parlour in delhi" /> */}
              <img title="Mens beauty parlour in noida"  src={facial} alt="Mens parlour in delhi" />
            </div>
            <h2 className="home-container-3-content-col-text">Facial</h2>
          </a>
          
          <a title="Best Way To Get Rid Of Pubic Hair" 
            href="#under-cloth-and-back"
            className="home-container-3-content-col "
          >
            <div className="home-container-3-content-col-img">
              {/* <img title="Groom Makeup"  src={wax} alt="corporate barber" /> */}
              <img title="Groom Makeup"  src={underCloth} alt="corporate barber" />
            </div>
            <h2 className="home-container-3-content-col-text">
              Under Cloth Wax
            </h2>
          </a>

          
          <a title="wax Near Me"  href="#full-body" className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              {/* <img title="Mens beauty services at home"  src={bodyWax} alt="best Makeup artist" /> */}
              <img title="Mens beauty services at home"  src={fullBody} alt="best Makeup artist" />
              {/* <div>
              <i className="fa-solid fa-box-open"></i>
                </div> */}
            </div>
            <h2 className="home-container-3-content-col-text">
              Full Body Wax
            </h2>
          </a>
          
          <Link target='"_blank' to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men's Wedding Groom Package. Please contact me." title="Underarm Waxing Near Me"  className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              {/* <img title="Best Mens salon in delhi" src={groom} alt="Best Mens salon in Noida" /> */}
              <img title="Best Mens salon in delhi" src={groomPackage} alt="Best Mens salon in Noida" />
            </div>
            <h2 className="home-container-3-content-col-text">
              Groom Package
            </h2>
          </Link>


          <a title="Area Hair Removal"  href="#pedicure" className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              <img title=" Mens waxing near me" src={manicure} alt="Mens waxing cost" />
            </div>
            <h2 className="home-container-3-content-col-text">Pedicure & Manicure</h2>
          </a>

          <a title=" Wax Near Me"  href="#shave" className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              {/* <img title="Mens waxing price" src={shave} alt="Mens waxing in Delhi" /> */}
              <img title="Mens waxing price" src={bodyTrim} alt="Mens waxing in Delhi" />
            </div>
            <h2 className="home-container-3-content-col-text">
              Body Trim & Shave
            </h2>
          </a>

          <a title="Wax Spa Near Me"  href="#honeymoon-special" className="home-container-3-content-col">
            <div className="home-container-3-content-col-img">
              {/* <img title="Mens waxing in Noida" src={spa} alt="Mens waxing in Ghaziabad" /> */}
              <img title="Mens waxing in Noida" src={honeymoon} alt="Mens waxing in Ghaziabad" />
            </div>
            <h2 className="home-container-3-content-col-text">Honeymoon Special </h2>
          </a>

          <Link target='"_blank' to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men's Holiday Special Grooming Service. Please contact me." className="home-container-3-content-col"  >
            <div className="home-container-3-content-col-img" onClick={()=>{setPopupBtn(true)}}>
              {/* <img title="Mens waxing in Greater noida" src={massage} alt="Mens waxing in Gurgaon" /> */}
              <img title="Mens waxing in Greater noida" src={holiday} alt="Mens waxing in Gurgaon" />
            </div>
            <h2 className="home-container-3-content-col-text">Holiday Special </h2>
          </Link>

        </div>
      </div>
    </div>
  );
};

export default LandingCategory;
