import React from 'react'
import LandingNav from '../../components/user/LandingNav'
import { Link } from 'react-router-dom'

const ConfirmBookingPage = () => {
  return (
    <div>
       
        <div>
        <LandingNav/>
      <div className="error-page">
        <h1>Thanks For Booking</h1>
        <h2>Our team will contact you shortly</h2>
        <h3>
          Contact : <a href="tel:+919658153153">+91 9658 153 153</a>
        </h3>
        <h3>
          <Link to="/">go to home <i className="fa-solid fa-right-to-bracket"></i></Link>
        </h3>
      </div>
    </div>


    
    </div>
  )
}

export default ConfirmBookingPage