import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuComponent from "./MenuComponent";


const LandingEnd = () => {
    const [menu, setMenu] = useState(false);

  const handleChange = () => {
    setMenu(!menu);
  };
    
  return (
    <div>
        
        <div className="home-fix-button">
        {/* -------------- fix menu start ----------------- */}
        {menu && (
          <MenuComponent open={menu} handleMenu={() => setMenu(false)} />
        )}

        {/* -------------- fix menu end ----------------- */}

        <div className="home-fix-button-col">
          <div className="home-fix-button-call">
            <Link title=" Wax"  target='"_blank' to="tel:01204116153">
              <div className="home-fix-button-icon">
                <i className="fa-solid fa-phone-volume"></i>
              </div>
              <div className="home-fix-button-text">0120 4116153</div>
            </Link>
          </div>

          <div className="home-fix-button-menu" onClick={handleChange}>
            {menu ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <i className="fa-solid fa-bars"></i>
            )}
          </div>

          <div className="home-fix-button-wa">
            <Link title=" Wax" 
              target='"_blank'
              to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men grooming Service. Please contact me."
            >
              <div className="home-fix-button-wa-icon">
                <i className="fa-brands fa-whatsapp"></i>
              </div>
              <div className="home-fix-button-text">9658-153-153</div>
            </Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export default LandingEnd