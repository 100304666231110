import React from 'react'

const LandingOffer = () => {
  return (
    <div>
        
        <div className="home-container-2">
          <div className="home-container-2-offer">
            <div className="home-container-2-offer-col-1">
              <i className="fa-solid fa-tag"></i>
            </div>
            <div className="home-container-2-offer-col-2">
              <div className="home-container-2-offer-col-2-heading">
                Get Upto 50% off on packages
              </div>
              <div className="home-container-2-offer-col-2-text">
                Limted time offer
              </div>
            </div>
          </div>
        </div>

    </div>
  )
}

export default LandingOffer