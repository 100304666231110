import React, { useState } from "react";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";

const BookingForm = ({setBookingForm}) => {

  const [loading, setLoading] = useState(false)

    
  const [user, setUser] = useState({
    name: "",
    phone: "",
    service: "",
    address:"",
    request :"",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({ 
      ...user,
      [e.target.name]: value,
    });
  };

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.glazma.com/booking-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        setBookingForm(false);
        alert("Service booked successfully");
        navigate("/booking-confirm");

      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);

  };
    
  return (
    <div>
      
{loading 
?
<div className="data-loading">
<LoadingComponent/>
</div>

:("")
}
      <div className="booking-form">
        <div className="booking-form-main">
        <div className="booking-form-close" onClick={()=>{setBookingForm(false)}}><i class="fa-solid fa-scissors"></i></div>
            
        <div className="booking-form-content">
          <div className="booking-form-heading-1">Book an Appointment</div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name*</label>
              <input required type="text" placeholder="Name" name="name" onChange={handleChange}/>
            </div>
            <div>
              <label>Number*</label>
              <input required type="number" placeholder="Number" name="phone" onChange={handleChange}/>
            </div>
            <div>
              <label>Service</label>
            <select name="service" onChange={handleChange}>
              {/* <option disabled selected>--- Select Model ---</option> */}

              <option disabled selected>
                Select service{" "}
              </option>
              <option value="Men's Basic Grooming">
                Men's Basic Grooming &nbsp; - &nbsp; Rs. 1,199/-
              </option>
              <option value="Men's Radiance Package">
                Men's Radiance Package &nbsp; - &nbsp; Rs. 1,599/-
              </option>
              <option value="Men's Relax & Groom Special">
                Men's Relax & Groom Special &nbsp; - &nbsp; Rs. 1,799/-
              </option>
              <option value="Men's Special Grooming">
                Men's Special Grooming &nbsp; - &nbsp; Rs. 2,199/-
              </option>
              <option value="Men's Oxy-Glow Package">
                Men's Oxy-Glow Package &nbsp; - &nbsp; Rs. 3,199/-
              </option>
              <option value="Men's Full Body Grooming">
                Men's Full Body Grooming &nbsp; - &nbsp; Rs. 4,399/-
              </option>
              <option value="Men's body Waxing">
                Men's body Waxing &nbsp; - &nbsp; Rs. 3,999/-
              </option>
              <option value="Men's Under Cloth Grooming">
                Men's Under Cloth Grooming &nbsp; - &nbsp; Rs. 6,599/-
              </option>
              <option value="Men's Honeymoon Special">
                Men's Honeymoon Special &nbsp; - &nbsp; Rs. 7,999/-
              </option>
              

              <option value="Men's Honeymoon Special">
              Men’s Regular Groom Package &nbsp; - &nbsp; Rs. 7,999/-
              </option>

              <option value="Men's Honeymoon Special">
              Men’s Exclusive Groom Package &nbsp; - &nbsp; Rs. 9,999/-
              </option>

              <option value="Men's Honeymoon Special">
              Men’s Premium Groom Package &nbsp; - &nbsp; Rs. 11,999/-
              </option>

              <option value="Men's Honeymoon Special">
              Men's Wedding Day Dapper Delight &nbsp; - &nbsp; Rs. 17,999/-
              </option>

              <option value="Men's Honeymoon Special">
              Men's Gleaming Groom's Journey &nbsp; - &nbsp; Rs. 29,999/-
              </option>


            </select>
            </div>

            <div>
              <label>Address*</label>
              <textarea required rows="2" type="text" placeholder="address / city" name="address" onChange={handleChange} />
            </div>
            <div>
              <label>Special request</label>
              <textarea
                rows="3"
                type="text"
                placeholder="any special request"
                name="request"
                onChange={handleChange}
              />
            </div>
            <button>Book Now</button>
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default BookingForm;
