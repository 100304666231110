import React from 'react'

const PackageComponent = () => {
  return (
    <div>
         <div className="home-container-4">
          {/* <div className="home-container-4-heading">Bestsellers</div> */}
          <div   id="basic-groom" className="home-container-4-heading">
            Our Packages
          </div>

          <div className="home-container-4-package">
            {/* ------------- package start ------------ */}

            <div  className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Basic Grooming
                </div>
                <div className="home-container-4-package-col-price">
                  <s>Rs.1,599</s> Rs. 1,199/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li id="radiance-package">Under Cloth Hair Trim</li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line ">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Radiance Package
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.1,999</s> Rs. 1,599/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Peel-Off Mask</li>
                    <li>Skin Lightening Glow</li>
                    <li id="relax-groom">VLCC Facial</li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Relax & Groom Special
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.2,199</s> Rs. 1,799/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Manicure </li>
                    <li id="special-grooming">Pedicure</li>
                    {/* <li>Head Massage</li> */}
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Special Grooming
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.2,599</s> Rs. 2,199/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Face Cleanup</li>
                    <li>Lotus Facial</li>
                    <li id="oxy-glow"> Scrub</li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Oxy-Glow Package
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.3,599</s> Rs. 3,199/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>O3+ Dtan</li>
                    <li id="full-body-grooming">O3+ Facial</li>
                    {/* <li>Steem </li> */}
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Full Body Grooming
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.4,799</s> Rs. 4,399/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>O3+ Dtan</li>
                    <li>O3+ Facial</li>
                    <li id="body-waxing">Manicure & Pedicure</li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's body Waxing
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.5,799</s> Rs. 3,999/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Full Body Wax</li>
                    <li id="under-cloth">Under Cloth Hair Shave </li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Under Cloth Grooming
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.8,999</s> Rs. 6,599/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Full Body Wax</li>
                    <li>Full Body Scrub</li>
                    <li id="honeymoon-special">Under Cloth Hair Shave</li>
                  </ul>
                  {/* Peel-Off Mask, Skin Lightening Glow With VLCC Facial */}
                </div>
              </div>
              {/* <div className="home-container-4-package-col-2">
                <div className="home-container-4-package-col-2-button">
                  <button>Add</button>
                </div>
              </div> */}
            </div>

            {/* ------------- package end ------------ */}

            <div className="home-container-hr-line">
              <hr />
            </div>

            {/* ------------- package start ------------ */}

            <div className="home-container-4-package-cols">
              <div className="home-container-4-package-col-1">
                <div className="home-container-4-package-col-heading-1">
                  <i className="fa-solid fa-box-open"></i> Package
                </div>
                <div className="home-container-4-package-col-heading-2">
                  Men's Honeymoon Special
                </div>
                <div className="home-container-4-package-col-price">
                <s>Rs.11,999</s> Rs. 7,999/-
                </div>
                <div className="home-container-4-package-col-list">
                  <ul>
                    <li>Full Body Wax</li>
                    <li>Full Body Polishing</li>
                    <li>Under Cloth Hair Shave</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* ------------- package end ------------ */}
          </div>
        </div>
    </div>
  )
}

export default PackageComponent