import React from 'react'
import logo2 from "../../media/images/glazma-logo-text.png";
import logo3 from "../../media/images/glazma-white-icon.png";
import { Link } from 'react-router-dom';


const LandingNav = () => {
  return (
    <div>
        <div className="home-navbar">
          {/* ------------------ nav-1 ---------------- */}
          <Link title="Men's salon at home" to="/" className="home-nav-1">
            <div className="home-nav-img-1">
              {/* <img src={logo1} alt="" /> */}
              <img src={logo3} alt="Men's salon at home" title='Mens grooming doorstep' />
            </div>
            <div className="home-nav-img-2">
              <img src={logo2} alt="Men's salon at home" title='Mens grooming doorstep'/>
            </div>
          </Link>
          {/* <button>BOOK NOW</button> */}
          {/* <div className="home-nav-icon"><i className="fa-solid fa-cart-shopping"></i></div> */}
          <Link title='Mens grooming doorstep' target='_blank' to='tel:919658153153' className="home-nav-btn">
            Book On Call
          </Link>
        </div>
    </div>
  )
}

export default LandingNav