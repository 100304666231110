import React from 'react'
import { Link } from 'react-router-dom';
import { TypeAnimation } from "react-type-animation";

const ChandigarhLandingHeader2 = () => {
  return (
    <div>
        
        <div className="home-container-1">
          <div className="home-container-1-heading">
            {/* Salon Prime for kids & men */}
            {/* Salon at Home in Greater Noida */}
            Salon at Home&nbsp;
            <div className="landing-small-display-none"></div>
            in&nbsp;
            {/* Greater Noida */}
            <TypeAnimation
              sequence={[
                "Chandigarh",
                2500,
                "Zirakpur",
                2500,
                "Panchkula",
                2500,
                "Mohali",
                2500,
              ]}
              speed={50}
              repeat={Infinity}
            />
          </div>
          <div className="home-container-1-col-2">
            <div>
              <i className="fa-solid fa-star"></i>
            </div>
            {/* <div className="home-container-1-col-2-text">4.87 (5.0 M bookings)</div> */}
            <Link title='Hair Waxing Near Me'
              to="https://www.google.com/search?kgmid=/g/11jjjvrtd1&hl=en-IN&q=Glazma+-Men%27s+Salon+at+Home&kgs=0b9fe95014d0537e&shndl=17&source=sh/x/kp/osrp/m5/1#lrd=0x390ce5bc6d74074b:0x5de0b5d8853aef45,1,,,,"
              target="_blank"
              className="home-container-1-col-2-text"
            >
              4.9 /5 (Rating on Google)
            </Link>
          </div>
        </div>
    </div>
  )
}

export default ChandigarhLandingHeader2;