import React from "react";
import { Link } from "react-router-dom";
import logo from "../../media/images/glazma-logo.png";
import del from "../../media/images/delhi.jpg"
import gur from "../../media/images/gurgaon.jpg"
import noi from "../../media/images/noida.jpg"
import gha from "../../media/images/Ghaziabad.jpg"
import far from "../../media/images/Faridabad.jpg"
import chan from "../../media/images/chandigarh1.png"

const LandingFooter = () => {
  return (
    <div>
      <div className="home-footer">
        <div className="home-footer-content">
          <div className="home-footer-col-1">
            <div className="home-footer-col-1-img">
              <img title="Male Waxing Near Me"  src={logo} alt="logo" />
            </div>
            <div className="home-footer-col-1-icon">
              <div>
                <Link title="Waxing Salons Near Me" 
                  to="https://www.facebook.com/glazmaofficial/"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
              </div>
              <div>
                <Link title="Wax Near Me"  to="https://twitter.com/glazmaofficial" target="_blank">
                  <i className="fa-brands fa-x-twitter"></i>
                </Link>
              </div>
              <div>
                <Link title=" Line Hair Removal" 
                  to="https://www.instagram.com/glazmaofficial/"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </div>
              <div>
                <Link title="Best Waxing Near Me" 
                  to="https://in.pinterest.com/glazmaofficial/"
                  target="_blank"
                >
                  <i className="fa-brands fa-pinterest"></i>
                </Link>
              </div>
              <div>
                <Link title="Hair Waxing Near Me"  to="https://www.youtube.com/@glazma" target="_blank">
                  <i className="fa-brands fa-youtube"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="home-footer-location">
            <div className="home-footer-location-heading">Available In</div>
            <div className="home-footer-location-content">

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Waxing Near Me"  src={del} alt="Pubic Hair Removal" /></div>
                <div className="home-footer-location-content-txt">Delhi</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Male Hair Removal Near Me"  src={gur} alt="Body Waxing Near Me" /></div>
                <div className="home-footer-location-content-txt">Gurugram</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Wax Places Near Me"  src={noi} alt="Leg Waxing Near Me" /></div>
                <div className="home-footer-location-content-txt">Noida</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Full Body Wax Near Me"  src={noi} alt=" Hair Removal" /></div>
                <div className="home-footer-location-content-txt">Greater Noida</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Back Waxing Near Me"  src={gha} alt="Wax Center Near Me" /></div>
                <div className="home-footer-location-content-txt">Ghaziabad</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Waxing Services Near Me"  src={far} alt="Best Way To Remove Pubic Hair" /></div>
                <div className="home-footer-location-content-txt">Faridabad</div>
              </div>
              

              <div className="home-footer-location-content-col">
                <div className="home-footer-location-content-img"><img title="Best Hair Removal"  src={chan} alt="Men's Waxing Near Me" /></div>
                <div className="home-footer-location-content-txt">Chandigarh</div>
              </div>
              
            </div>
          </div>

          {/* <div className="home-footer-col-2">
            <div className="home-footer-col-2-cols">
              <div className="home-footer-col-2-heading">Company</div>
              <div className="home-footer-col-2-points">
                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <Link title=""  to="/" className="home-footer-col-2-point-text">
                    Home
                  </Link>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <Link title=""  to="" className="home-footer-col-2-point-text">About</Link>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Contact</div>
                </div>

                <div>
                  <Link title=""  to="/other"  className="home-footer-col-2-point-icon">✔</Link>
                  <div className="home-footer-col-2-point-text">
                    Privacy Policy
                  </div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">
                    Term & Condition
                  </div>
                </div>
              </div>
            </div>

            <div className="home-footer-col-2-cols">
              <div className="home-footer-col-2-heading">Avaliable in</div>
              <div className="home-footer-col-2-points">
                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Delhi</div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Noida</div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">
                    Greater Noida
                  </div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Gurugram</div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Ghaziabad</div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">✔</div>
                  <div className="home-footer-col-2-point-text">Faridabad</div>
                </div>
              </div>
            </div>

            <div className="home-footer-col-2-cols">
              <div className="home-footer-col-2-heading">Corporate Office</div>
              <div className="home-footer-col-2-points">
                <div>
                  <div className="home-footer-col-2-point-icon">
                    <i className="fa-solid fa-location-arrow"></i>
                  </div>
                  <div className="home-footer-col-2-point-text">
                    <Link title=""  target='"_blank' to="https://g.co/kgs/o6aCWLd">
                      210, 2nd Floor, Ocean Plaza, <br />
                      Sector-18, Noida - 201301
                    </Link>
                  </div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">
                    <i className="fa-solid fa-phone-volume"></i>
                  </div>
                  <div className="home-footer-col-2-point-text">
                    <Link title=""  target='"_blank' to="tel:01204116153">
                      0120 4116153{" "}
                    </Link>
                  </div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">
                    <i className="fa-regular fa-envelope"></i>
                  </div>
                  <div className="home-footer-col-2-point-text">
                    <Link title=""  target='"_blank' to="mailto:info@glazma.com">
                      info@glazma.com{" "}
                    </Link>
                  </div>
                </div>

                <div>
                  <div className="home-footer-col-2-point-icon">
                    <i className="fa-brands fa-whatsapp"></i>
                  </div>
                  <div className="home-footer-col-2-point-text">
                    <Link title="" 
                      target='"_blank'
                      to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men grooming Service. Please contact me."
                    >
                      +91 9658-153-153{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
          </div> */}
        </div>
      </div>

      <div className="home-footer-cc">
        Copyright <i className="fa-regular fa-copyright"></i> 2019, GLAZMA. All
        Rights Reserved.
      </div>
    </div>
  );
};

export default LandingFooter;
